<app-logged class="store-list">
  <header class="store-list__header">
    <h2>AGENDAMENTO DE TRANSFERENCIAS PARA LIQUIDAÇÃO DE PARCEIROS</h2>

    <div class="store-list__header__actions">
      <div>
        <button class="btn-primary-light" mat-button (click)="downloadReport()">Exportar essa lista</button>
      </div>
    </div>
  </header>

  <div class="row" *ngIf="schedules">
    <div class="col-sm-12">
      <div class="table-responsive">
        <app-form-filter
          maskExample="00.000.000/0000-00"
          actions="form"
          type="transferScheduleList"
          [fieldsForm]="fieldsForm"
          (returnFilter)="reciverFeedback($event)"
          [formGroupValue]="formGroup"
        >
        </app-form-filter>
        <table mat-table [dataSource]="schedules">
          <ng-container matColumnDef="receiver">
            <th mat-header-cell *matHeaderCellDef>Parceiro</th>
            <td mat-cell *matCellDef="let element">
              {{ element.transfer[0]?.storeName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cnpj">
            <th mat-header-cell *matHeaderCellDef>Cnpj</th>
            <td mat-cell *matCellDef="let element">
              {{ element.transfer[0]?.storeCnpj | mask: '00.000.000/0000-00' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef>Agendado para</th>
            <td mat-cell *matCellDef="let element">
              {{ element.dueDate | date: 'dd/MM/yyyy' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="reason">
            <th mat-header-cell *matHeaderCellDef>Tipo</th>
            <td mat-cell *matCellDef="let element">
              {{
                element.reason == 'liquidation'
                  ? 'Liquidação de parceiros'
                  : 'Taxa administrativa'
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="approvalStatus">
            <th mat-header-cell *matHeaderCellDef>Status do Agendamento da TED</th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.status == enumTransferApprovalStatus.PENDING_APPROVAL" class="pending">
                Aprovação Pendente
              </span>
              <span *ngIf="element.status == enumTransferApprovalStatus.APPROVAL_GRANTED" class="approved">
                Aprovação Concedida
              </span>
              <span *ngIf="element.status == enumTransferApprovalStatus.APPROVAL_REJECTED" class="reject">
                Aprovação Rejeitada
              </span>
              <span *ngIf="element.status == enumTransferApprovalStatus.APPROVAL_EXPIRED" class="expired">
                Aprovação Expirada
              </span>
              <span *ngIf="element.status == enumTransferApprovalStatus.ERROR" class="error">
                Erro
              </span>
              <span *ngIf="element.status == enumTransferApprovalStatus.EXECUTED" class="executed">
                Executado
              </span>
            </td>
          </ng-container>
          

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>Criado em</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdAt | date: 'dd/MM/yyyy' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="totalValue">
            <th mat-header-cell *matHeaderCellDef>Valor</th>
            <td mat-cell *matCellDef="let element">
              {{ totalValue(element.transfer) | currency: 'BRL':'R$' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="paidAt">
            <th mat-header-cell *matHeaderCellDef>Pago em</th>
            <td mat-cell *matCellDef="let element">
              {{ (element.paidAt | date: 'dd/MM/yyyy') || ' - ' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Ações</th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                matTooltip="Transações"
                matTooltipPosition="left"
                routerLink="/dashboard/liquidation-transctions/{{ element.id }}"
              >
                <mat-icon>swap_horiz</mat-icon>
              </button>
              <button
                mat-icon-button
                matTooltip="Detalhe"
                matTooltipPosition="left"
                routerLink="/dashboard/liquidation-transfer-schedule/details/{{ element.id }}"
              >
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
          <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
        </table>
        <app-form-filter
          actions="pagination"
          type="transferScheduleList"
          [length]="pageTotal"
          [pageSizeOptions]="pageSizeOptions"
          (returnFilter)="reciverFeedback($event)"
          [formGroupValue]="formGroup"
        ></app-form-filter>
      </div>
    </div>
    
  </div>
</app-logged>
