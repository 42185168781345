import { Component, OnInit, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { MatPaginator, MatTableDataSource } from '@angular/material'
import { Params } from '@angular/router'
import JsFileDownloader from 'js-file-downloader'
import { enumTransferApprovalStatus } from 'src/app/models/enumTransferApprovalStatus'
import { LiquidationTransferService } from 'src/app/services/liquidationTransfer.service'

@Component({
  selector: 'app-transfer-schedule-list',
  templateUrl: './liquidation-transfer-schedules-list.component.html',
  styleUrls: ['./liquidation-transfer-schedules-list.component.scss'],
})
export class LiquidationTransferSchedulesList implements OnInit {
  public schedules: any

  public headersTable: string[] = ['receiver', 'cnpj', 'dueDate', 'reason', 'approvalStatus', 'totalValue', 'createdAt', 'paidAt','actions']

  fieldsForm: any
  public returnedFilters: any
  public pageTotal: number
  dataSource: any
  resultsAll: any
  joinFilter: string

  public formGroup: FormGroup
  public params: Params
  public enumTransferApprovalStatus = enumTransferApprovalStatus
  
  page = 1
  pageSize = 10
  pageSizeOptions: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  constructor (
    private readonly liquidationTransferService: LiquidationTransferService,
    
  ) {

  }

  ngOnInit (): void {
    this.renderScheduleList()
    

    this.fieldsForm = [
      {
        field: 'input',
        type: 'text',
        formControlName: 'cnpjTransferScheduleList',
        label: 'CNPJ',
        placeholder: 'CNPJ',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Agendado para - Data Início',
        formControlName: 'startDueDateTransferScheduleList',
      },
      {
        formControlName: 'endDueDateTransferScheduleList',
        field: 'input',
        type: 'date',
        label: 'Agendado para - Data Final',
      },
      {
        field: 'input',
        type: 'date',
        label: 'Pago em - Data Início',
        formControlName: 'startPaidAtDateTransferScheduleList',
      },
      {
        formControlName: 'endPaidAtDateTransferScheduleList',
        field: 'input',
        type: 'date',
        label: 'Pago em - Data Final',
      },
      {
        field: 'select',
        formControlName: 'reasonTransferScheduleList',
        label: 'Tipo Transferência',
        placeholder: 'Tipo Transferência',
        list: [
          { key: 'administrative_tax', value: '	Transferencias de taxa administrativa' },
          { key: 'liquidation', value: '	Liquidação de parceiros' },
        ],
        optionDefault: 'TODOS',
      },
      {
        field: 'select',
        formControlName: 'approvalStatusTransferScheduleList',
        label: 'Status do Agendamento da TED',
        placeholder: 'Status do Agendamento da TED',
        list: [
          { key: enumTransferApprovalStatus.PENDING_APPROVAL, value: 'Pendente de aprovação' },
          { key: enumTransferApprovalStatus.APPROVAL_GRANTED, value: 'Aprovação concedida' },
          { key: enumTransferApprovalStatus.APPROVAL_REJECTED, value: 'Aprovação rejeitada' },
          { key: enumTransferApprovalStatus.APPROVAL_EXPIRED, value: 'Aprovação expirada' },
          { key: enumTransferApprovalStatus.ERROR, value: 'Erro' },
          { key: enumTransferApprovalStatus.EXECUTED, value: 'Executado' },
        ],
        optionDefault: 'TODOS',
      },
    ]
  }

  async renderScheduleList () {
    const { data, pagination } = await this.liquidationTransferService.listShedules(`?&page=${this.page}&limit=${this.pageSize}`).toPromise()
    this.schedules = new MatTableDataSource(data)
    this.pageTotal = pagination.TotalRegisters
  }

  totalValue (transfer: any) {
    return transfer.length ?
      transfer.find(() => true).totalValue
      : 0
  }

  reciverFeedback (returnFilter) {
    // this.returnedFilters = returnFilter.data
    this.pageTotal = returnFilter.pagination.TotalRegisters
    this.page = returnFilter.pagination.CurrentPage
    this.schedules = new MatTableDataSource(returnFilter.data)
    this.resultsAll = returnFilter.data
    this.formGroup = returnFilter.formGroup.value
    this.joinFilter = returnFilter.joinFilter
    this.params = {
      formGroupValue: JSON.stringify(this.formGroup),
    }
  }

  async downloadReport(){    
    const filters = this.joinFilter ? `?${this.joinFilter}` : '';   
    console.log('filters', filters)
    const result = await this.liquidationTransferService.downloadReport(filters).toPromise()
    new JsFileDownloader({
      url: result.fileUrl,     
    }).start()
  }

  getLastTransferStatus(schedule) {
    console.log(schedule)
    const transfer = schedule.transfer.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0]
    return transfer.status
  }
}
