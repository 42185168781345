import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'

import { ErrorsService } from 'src/app/core/services/errors.service'
import { ZipCodeService } from 'src/app/core/services/zip-code.service'

import { Router, ActivatedRoute } from '@angular/router'
import { MatSnackBar } from '@angular/material'
import { Location } from '@angular/common'
import { LyDialog } from '@alyle/ui/dialog'
import { base64ToBlob } from 'base64-blob'
import { ImgCropperEvent } from '@alyle/ui/image-cropper'
import { NgxImageCompressService } from 'ngx-image-compress'
import { map, startWith } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { Store } from 'src/app/models/store'
import { CropperDialogComponent } from '../../../../components/cropper-dialog/cropper-dialog.component'
import { PreRegistrationService } from '../../../../services/preRegistration.service'
import { StoreService } from '../../../../services/store.service'
import { AuthService } from 'src/app/services/auth.service'
import JsFileDownloader from 'js-file-downloader'

import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete'
import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { CompaniesService } from '../../../../services/companies.service'
import moment from 'moment'

@Component({
  selector: 'app-pre-registration-airports-form',
  templateUrl: './pre-registration-form.component.html',
  styleUrls: ['./pre-registration-form.component.scss'],
})
export class PreRegistrationFormComponent implements OnInit {
  public formGroup: FormGroup
  public preRegistrationID: string
  public params = { formGroupValue: [] }

  public originalDocumentFrontImage: any
  public croppedDocumentFrontImage: any
  public documentFrontImagePosition: any
  public documentFrontImageData: any
  public readUrlDocumentFront: any

  public originalDocumentBehindImage: any
  public croppedDocumentBehindImage: any
  public documentBehindImagePosition: any
  public documentBehindImageData: any
  public readUrlDocumentBehind: any

  public frontImageInvalid = false
  public behindImageInvalid = false
  public firstPaymentEnabled = false
  public planSelectEnabled = false

  public storeOptions: Observable<any[]>
  public stores: any[] = []
  public store: any[] = []
  public storeName = ''
  public pointsConversionFactorOut
  public jcoinsInvalid = false
  public documentBehind: string
  public documentFront: string
  public typePreRegistration: string

  public documentUrl: any
  public uploadData: FormData
  public approveDoc: string
  public disapprovedDocument = false
  public approvedDocument: boolean

  public selectedCompanies: any[] = []
  filteredCompanies: Observable<string[]>
  public companyControl = new FormControl()
  public selectedStatus: any[] = []
  separatorKeysCodes: number[] = [ENTER, COMMA]
  public companies: any[] = []
  public notCpnj = false
  public showPlan = false
  public companyCnpj: string
  public otherProfession = false
  public cpf: any

  public storeReferralOthers = false

  @ViewChild('companyInput', { static: false }) companyInput: ElementRef<HTMLInputElement>
  @ViewChild('autoCompany', { static: false }) matAutocomplete: MatAutocomplete

  @ViewChild('fileInputBanner', { static: false }) fileInputBanner: ElementRef

  constructor (
    public errorsService: ErrorsService,
    private readonly formBuilder: FormBuilder,
    public preRegistrationService: PreRegistrationService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly zipCodeService: ZipCodeService,
    private readonly _dialog: LyDialog,
    private readonly _cd: ChangeDetectorRef,
    private readonly imageCompress: NgxImageCompressService,
    public authService: AuthService,
    public storeService: StoreService,
    private readonly location: Location,
    private readonly companiesService: CompaniesService,
  ) { }

  ngOnInit () {
    this.preRegistrationID = this.route.snapshot.paramMap.get('preRegistrationID')
    this.typePreRegistration = this.route.snapshot.paramMap.get('type')
    this.initForm().catch(err => console.log(err))

    if (this.typePreRegistration !== 'architects' || (this.typePreRegistration === 'architects' && this.preRegistrationID)) {
      this.showPlan = true
    }
  }

  async initForm () {
    this.route.queryParams.subscribe((params) => {
      this.params.formGroupValue = params.formGroupValue
    })
    this.formGroup = this.formBuilder.group({
      cpf: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      gender: [null, []],
      birthDate: [null, []],
      emailAddress: [null, [Validators.email]],
      phoneNumber: [null, []],
      isMembership: [null, []],
      planMembership: [null, []],
      firstPayment: [null, []],
      store: [null, []],
      jcoins: [null, [Validators.min(1), Validators.pattern('^(0|[1-9][0-9]*)$')]],
      pointsConversionFactorOut: [{ value: null, disabled: true }, []],
      // crea: [null, []],
      // cau: [null, []],
      // asbea: [null, []],
      documentCode: [null, []],
      documentType: [null, []],
      companyName: [null, []],
      companyCnpj: [null, []],
      documentArchitect: [null, []],
      step1: [null, []],
      disapproved: [null, []],
      profession: [null, []],
      companiesInput: [null, []],
      origin: [null, []],
      companyInput: [null, []],
      otherProfession: [null, []],
      nickname: [null, []],
      operatingTime: [null, []],
      // academicEducation: [null, []],
      maritalStatus: [null, []],
      facebook: [null, []],
      instagram: [null, []],
      regType: [null, []],
      licensePlate: [null, []],
      companies: [{ value: null, disabled: true }, []],
      storeReferralName: [{ value: null, disabled: true }, []],
      storeReferralOthers: [{ value: null, disabled: true }, []],
    })

    await this.storeService
      .listStores()
      .toPromise()
      .then(
        async response => {
          this.stores = response
        },
        error => {
          console.log(error)
          this.snackBar.open(error.error.message)
        },
      )

    this.storeOptions = this.formGroup.controls.store.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this.stores.filter(store => {
          const newValue = typeof value === 'object' ? value.nomeFantasia : value
          return (
            store.nomeFantasia.toLowerCase().includes(newValue.toLowerCase()) ||
            store.razaoSocial.toLowerCase().includes(newValue.toLowerCase()) ||
            store.cnpj.toLowerCase().includes(newValue.toLowerCase())
          )
        })
      }),
    )

    if (this.typePreRegistration === 'architects') {
      await this.companiesService
        .list()
        .then(
          async response => {
            this.companies = response.companies
          },
          error => {
            console.log(error)
            this.snackBar.open(error.error.message)
          },
        )

      this.filteredCompanies = this.companyControl.valueChanges.pipe(
        startWith(''),
        map((company: any) => (
          company ? this._filterCompanies(company) : this.companies.slice(0, 20))),
      )
    } else {
      this.formGroup.get('nickname').setValidators(null)
      this.formGroup.get('nickname').updateValueAndValidity()
      this.formGroup.get('nickname').setErrors(null)

      this.formGroup.get('documentType').setValidators(null)
      this.formGroup.get('documentType').updateValueAndValidity()
      this.formGroup.get('documentType').setErrors(null)

      this.formGroup.get('documentCode').setValidators(null)
      this.formGroup.get('documentCode').updateValueAndValidity()
      this.formGroup.get('documentCode').setErrors(null)

      // this.formGroup.get('academicEducation').setValidators(null)
      // this.formGroup.get('academicEducation').updateValueAndValidity()
      // this.formGroup.get('academicEducation').setErrors(null)

      this.formGroup.get('maritalStatus').setValidators(null)
      this.formGroup.get('maritalStatus').updateValueAndValidity()
      this.formGroup.get('maritalStatus').setErrors(null)

      this.formGroup.get('operatingTime').setValidators(null)
      this.formGroup.get('operatingTime').updateValueAndValidity()
      this.formGroup.get('operatingTime').setErrors(null)

      this.formGroup.get('emailAddress').setValidators(null)
      this.formGroup.get('emailAddress').updateValueAndValidity()
      this.formGroup.get('emailAddress').setErrors(null)

      this.formGroup.get('companyName').setValidators(null)
      this.formGroup.get('companyName').updateValueAndValidity()
      this.formGroup.get('companyName').setErrors(null)

      this.formGroup.get('birthDate').setValidators(null)
      this.formGroup.get('birthDate').updateValueAndValidity()
      this.formGroup.get('birthDate').setErrors(null)

      this.formGroup.get('gender').setValidators(null)
      this.formGroup.get('gender').updateValueAndValidity()
      this.formGroup.get('gender').setErrors(null)

      this.formGroup.get('profession').setValidators(null)
      this.formGroup.get('profession').updateValueAndValidity()
      this.formGroup.get('profession').setErrors(null)

      this.formGroup.get('profession').setValidators(null)
      this.formGroup.get('profession').updateValueAndValidity()
      this.formGroup.get('profession').setErrors(null)
    }
    if (this.preRegistrationID) {
      await this.loadInfos().catch(err => console.log(err))
    }
  }

  private _filterCompanies (company: string): string[] {
    const remove = String(company)

    if (typeof remove !== 'string') {
      return
    }

    return this.companies
      .filter(c => !this.selectedCompanies.includes(c))
      .filter(c => `${String(c.cnpj)} - ${String(c.companyName)}`.toLowerCase().includes(remove.toLowerCase()))
      .slice(0, 20)
  }

  selected (event: MatAutocompleteSelectedEvent): void {
    if (this.selectedCompanies.length < 1) {
      this.selectedCompanies.push(event.option.value)
      this.companyControl.setValue(null)
      if (this.selectedCompanies.length) {
        this.formGroup.patchValue({
          companyName: event.option.value.companyName,
        })
        this.companyInput.nativeElement.value = ''
        this.companyControl.setValue(null)
      }
    }
  }

  remove (value: any): void {
    if (value.companyName) {
      const index = this.selectedCompanies.indexOf(value)
      if (index >= 0) {
        this.selectedCompanies.splice(index, 1)
      }
    }
  }

  async loadInfos () {
    let response
    let responseArchitect
    if (this.typePreRegistration !== 'architects') {
      response = await this.preRegistrationService.findOne(this.preRegistrationID)
    }
    if (this.typePreRegistration === 'architects') {
      const architect = await this.preRegistrationService.findOneArchitect(this.preRegistrationID)
      response = architect.preRegistration
      responseArchitect = architect
      this.documentUrl = responseArchitect.documentUrl
      this.approveDoc = responseArchitect.documentName
      this.formGroup.patchValue({
        disapproved: responseArchitect.disapproved,
        step1: responseArchitect.approvedBy ? 'true' : responseArchitect.disapprovedBy ? 'false' : null,
        // crea: responseArchitect.crea,
        // cau: responseArchitect.cau,
        // asbea: responseArchitect.asbea,
        documentCode: responseArchitect.documentCode,
        documentType: responseArchitect.documentType,
        companyName: responseArchitect.companyName,
        // companyCnpj: responseArchitect.companyCnpj,
        profession: responseArchitect.profession,
        otherProfession: responseArchitect.otherProfession,
        nickname: responseArchitect.nickname,
        operatingTime: responseArchitect.operatingTime,
        // academicEducation: responseArchitect.academicEducation,
        maritalStatus: responseArchitect.maritalStatus,
        facebook: responseArchitect.facebook,
        instagram: responseArchitect.instagram,
        regType: responseArchitect.regType,
        licensePlate: responseArchitect.licensePlate,
        companies: responseArchitect.companies,
        storeReferralName:
          responseArchitect.storeReferralArchitect ?
            responseArchitect.storeReferralArchitect.nomeFantasia :
            '',
        storeReferralOthers: responseArchitect.storeReferralOthers,
      })
      this.storeReferralOthers = !!responseArchitect.storeReferralOthers
      this.otherProfession = responseArchitect.profession === 'OTHERS'
      if (responseArchitect.disapprovedBy) {
        this.disapprovedDocument = !!responseArchitect.disapproved
      }
      if (responseArchitect.companyCnpj) {
        const filterCpnj = this.companies.filter(item =>
          responseArchitect.companyCnpj === item.cnpj,
        )
        if (filterCpnj.length) {
          filterCpnj.map(item => {
            this.selectedCompanies.push(item)
            this.notCpnj = false
          })
        } else {
          this.formGroup.patchValue({
            companiesInput: responseArchitect.companyCnpj,
          })
          this.notCpnj = true
        }
      }
    }
    this.documentBehind = response.documentBehind
    this.documentFront = response.documentFront
    this.formGroup.patchValue({
      cpf: response.cpf,
      firstName: response.firstName,
      lastName: response.lastName,
      gender: response.gender,
      birthDate: response.birthDate ? response.birthDate.substring(0, 10) : null,
      emailAddress: response.emailAddress,
      phoneNumber: response.phoneNumber,
      isMembership: response.isMembership,
      planMembership: response.planMembership,
      firstPayment: response.firstPayment ? response.firstPayment.substring(0, 10) : null,
      pointsConversionFactorOut: response.balance,
      jcoins: response.balance,
      documentCode: responseArchitect ? responseArchitect.documentCode : null,
      documentType: responseArchitect ? responseArchitect.documentType : null,
      origin: response.origin,
    })

    if (response.balance > 0) {
      this.formGroup.get('jcoins').disable()
      this.formGroup.get('store').disable()
    }
    this.stores.forEach(store => {
      if (store.id === response.storeId) {
        this.formGroup.patchValue({
          store: store,
        })
      }
    })
    this.changeMembershipEnabled({ value: response.isMembership })
    this.changeTypePlan({ value: response.planMembership })
    this.croppedDocumentFrontImage = response.imgDocumentFront
    this.croppedDocumentBehindImage = response.imgDocumentBehind

    if (this.typePreRegistration === 'architects') {
      this.formGroup.get('cpf').disable()
      this.cpf = response.cpf
    }
  }

  changeTypePlan (event) {
    if (event.value === 'recurrent') {
      this.firstPaymentEnabled = true
      this.formGroup.get('firstPayment').setValidators([Validators.required])
      this.formGroup.get('firstPayment').updateValueAndValidity()
    } else {
      this.firstPaymentEnabled = false
      this.formGroup.get('firstPayment').setValidators(null)
      this.formGroup.get('firstPayment').setErrors(null)
    }
  }

  changeMembershipEnabled (event) {
    if (event.value === true) {
      this.planSelectEnabled = true
      this.formGroup.get('planMembership').setValidators([Validators.required])
      this.formGroup.get('planMembership').updateValueAndValidity()
    } else {
      this.firstPaymentEnabled = false
      this.planSelectEnabled = false
      this.formGroup.get('planMembership').setValidators(null)
      this.formGroup.get('planMembership').setErrors(null)
    }
  }

  selectFileDocumentFront (event: Event) {
    const originalDocumentFrontImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalDocumentFrontImage = reader.result
    }
    try {
      reader.readAsDataURL(originalDocumentFrontImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedDocumentFrontImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedDocumentFrontImage = result.img.dataURL
          this.documentFrontImagePosition = result.config
          this.documentFrontImageData = await base64ToBlob(this.croppedDocumentFrontImage)
          this.frontImageInvalid = !this.croppedDocumentFrontImage
          this.documentFront = this.croppedDocumentFrontImage
          this._cd.markForCheck()
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  async editDocumentFrontImage () {
    try {
      const img = this.originalDocumentFrontImage
        ? this.originalDocumentFrontImage
        : await this.getBase64ImageFromUrl(this.croppedDocumentFrontImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.documentFrontImagePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedDocumentFrontImage = result.img.dataURL
        this.documentFrontImagePosition = result.config
        this.documentFrontImageData = await base64ToBlob(this.croppedDocumentFrontImage)
        this.frontImageInvalid = !this.croppedDocumentFrontImage
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  selectFileDocumentBehind (event: Event) {
    const originalDocumentBehindImageUrl = (event.target as any).files[0]

    const reader: FileReader = new FileReader()
    reader.onloadend = e => {
      this.originalDocumentBehindImage = reader.result
    }
    try {
      reader.readAsDataURL(originalDocumentBehindImageUrl)
    } catch (e) {
      this.snackBar.open(e.message)
    }

    this.croppedDocumentBehindImage = null!
    this._dialog
      .open<CropperDialogComponent, any>(CropperDialogComponent, {
      data: { img: event, config: { width: 38 * 16, height: 10 * 16 } },
      width: 650,
      disableClose: true,
    })
      .afterClosed.toPromise()
      .then(async (result?: { img: ImgCropperEvent, config: any }) => {
        if (result) {
          this.croppedDocumentBehindImage = result.img.dataURL
          this.documentBehindImagePosition = result.config
          this.documentBehindImageData = await base64ToBlob(this.croppedDocumentBehindImage)
          this.behindImageInvalid = !this.croppedDocumentBehindImage
          this.documentBehind = this.croppedDocumentBehindImage
          this._cd.markForCheck()
        }
      })
      .catch(error => {
        this.snackBar.open(error.message)
      })
  }

  async editDocumentBehindImage () {
    try {
      const img = this.originalDocumentBehindImage
        ? this.originalDocumentBehindImage
        : await this.getBase64ImageFromUrl(this.croppedDocumentBehindImage)

      const result: { img: ImgCropperEvent, config: any } | undefined = await this._dialog
        .open(CropperDialogComponent, {
          data: {
            img,
            config: { ...this.documentBehindImagePosition, width: 38 * 16, height: 10 * 16 },
          },
          width: 650,
          disableClose: true,
        })
        .afterClosed.toPromise()
      if (result) {
        this.croppedDocumentBehindImage = result.img.dataURL
        this.documentBehindImagePosition = result.config
        this.documentBehindImageData = await base64ToBlob(this.croppedDocumentBehindImage)

        this.behindImageInvalid = !this.croppedDocumentBehindImage
        this._cd.markForCheck()
      }
    } catch (error) {
      this.snackBar.open(error.message)
    }
  }

  async getBase64ImageFromUrl (imageUrl) {
    const res = await fetch(imageUrl, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      keepalive: false,
      referrer: 'origin-when-cross-origin',
    })
    const blob = await res.blob()

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result)
        },
        false,
      )
      reader.readAsDataURL(blob)
    })
  }

  async removeImage (input) {
    if (input === 'documentFront') {
      this.readUrlDocumentFront = ''
      this.originalDocumentFrontImage = undefined
      this.croppedDocumentFrontImage = undefined
      this.documentFrontImagePosition = undefined
      this.documentFrontImageData = undefined

      this.frontImageInvalid = true
    }
    if (input === 'documentBehind') {
      this.readUrlDocumentBehind = ''
      this.originalDocumentBehindImage = undefined
      this.croppedDocumentBehindImage = undefined
      this.documentBehindImagePosition = undefined
      this.documentBehindImageData = undefined

      this.behindImageInvalid = true
    }
  }

  displayStore (store) {
    return store ? `${store.nomeFantasia}` : ''
  }

  calcValue (event) {
    this.stores.forEach(store => {
      if (store.id === event.option.value.id) {
        this.store.push(store)
        this.formGroup.patchValue({
          store: this.formGroup.value.store,
        })
      }
    })
    this.jcoinsInvalid = !this.formGroup.value.store
  }

  calcValuePoints (event) {
    this.formGroup.patchValue({
      pointsConversionFactorOut: this.formGroup.value.jcoins,
    })
    if (!this.formGroup.value.jcoins) this.jcoinsInvalid = false
  }

  async goBack () {
    // this.location.back()
    if (this.typePreRegistration === 'architects') {
      await this.router.navigate(['./dashboard/pre-registration-architects/architects'])
    } else if (this.typePreRegistration === 'CMS') {
      await this.router.navigate(['./dashboard/pre-registration/CMS'])
    } else {
      await this.router.navigate(['./dashboard/pre-registration'])
    }
  }

  async selectFile (event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader()

      reader.readAsDataURL(event.target.files[0]) // read file as data url

      reader.onload = () => {
        this.documentUrl = reader.result
      }

      this.uploadData = new FormData()
      this.uploadData.append('file', event.target.files[0], event.target.files[0].name)
      this.approveDoc = event.target.files[0].name
    } else {
      this.removeDocument()
    }
  }

  removeDocument () {
    this.documentUrl = null
    this.uploadData = null
    this.approveDoc = undefined
    this.fileInputBanner.nativeElement.value = ''
  }

  download (event) {
    event.preventDefault()
    // eslint-disable-next-line no-new
    new JsFileDownloader({
      url: this.documentUrl,
      nameCallback: () => {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return this.approveDoc
      },
    })
  }

  changeApproved (event: any) {
    if (event) {
      this.disapprovedDocument = false
      this.approvedDocument = true
      this.formGroup.get('disapproved').setValidators(null)
      this.formGroup.get('disapproved').updateValueAndValidity()
      this.formGroup.get('disapproved').setErrors(null)
      this.formGroup.value.disapproved = null
    } else {
      this.disapprovedDocument = true
      this.approvedDocument = false
      this.formGroup.get('disapproved').setValidators([Validators.required, Validators.min(1)])
      this.formGroup.get('disapproved').updateValueAndValidity()
      this.formGroup.value.approved = null
    }
  }

  isChecked (disapprovedDocument: boolean) {
    if (this.preRegistrationID) {
      if (!disapprovedDocument) {
        return 'checked'
      } else if (disapprovedDocument) {
        return 'checked'
      }
    }
  }

  valueInput (e) {
    if (e.target.value) {
      this.formGroup.value.companiesInput = e.target.value.replace(/[^\w]/g, '')
      this.companyCnpj = e.target.value.replace(/[^\w]/g, '')
      // this.notCpnj = true
    }
    // else {
    //   this.notCpnj = false
    // }
  }

  others (e: any) {
    if (e === 'OTHERS') {
      this.otherProfession = true
    } else {
      this.otherProfession = false
    }
  }

  async submit () {
    if (this.formGroup.value.jcoins) {
      this.jcoinsInvalid = !this.formGroup.value.store
    }

    if (!this.formGroup.valid || this.jcoinsInvalid) {
      this.snackBar.open('Preencha corretamente os campos e tente novamente.')
      return false
    }

    if (this.croppedDocumentFrontImage && this.documentFrontImageData) {
      const response = await this.authService.getS3Url('documentFront')
      this.readUrlDocumentFront = response.readUrl
      await this.authService.uploadToS3(response.uploadUrl, this.documentFrontImageData)
    }

    if (this.croppedDocumentBehindImage && this.documentBehindImageData) {
      const response = await this.authService.getS3Url('documentBehind')
      this.readUrlDocumentBehind = response.readUrl
      await this.authService.uploadToS3(response.uploadUrl, this.documentBehindImageData)
    }

    if (this.typePreRegistration !== 'architects') {
      const data = {
        cpf: this.formGroup.value.cpf,
        firstName: this.formGroup.value.firstName,
        lastName: this.formGroup.value.lastName,
        gender: this.formGroup.value.gender,
        emailAddress: this.formGroup.value.emailAddress,
        phoneNumber: this.formGroup.value.phoneNumber,
        membershipEnabled: this.formGroup.value.membershipEnabled,
        imgDocumentFront: this.readUrlDocumentFront,
        imgDocumentBehind: this.readUrlDocumentBehind,
        birthDate: this.formGroup.value.birthDate ? moment(this.formGroup.value.birthDate).format('YYYY-MM-DD') : null,
        isMembership: this.formGroup.value.isMembership,
        planMembership: this.formGroup.value.planMembership,
        firstPayment: this.formGroup.value.firstPayment,
        documentFront: this.readUrlDocumentFront ? this.documentFront : undefined,
        documentBehind: this.readUrlDocumentBehind ? this.documentBehind : undefined,
        balance: this.formGroup.value.jcoins,
        storeId: this.formGroup.value.store ? this.formGroup.value.store.id : undefined,
        origin: this.formGroup.value.origin ? this.formGroup.value.origin : undefined,
        typeRegistration: this.typePreRegistration ? this.typePreRegistration.toUpperCase() : null,
      }
      if (this.preRegistrationID) {
        await this.preRegistrationService.update(this.preRegistrationID, data).then(
          async response => {
            if (response.isUpdated) {
              if (this.typePreRegistration === 'CMS') {
                await this.router.navigate(['./dashboard/pre-registration/CMS'])
              } else if (this.typePreRegistration === 'others') {
                await this.router.navigate(['./dashboard/pre-registration'])
              }
              this.snackBar.open('Pré-Cadastro atualizada com sucesso.')
            }
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
      } else {
        await this.preRegistrationService.create(data).then(
          async response => {
            if (response.isCreated) {
              if (this.typePreRegistration === 'CMS') {
                await this.router.navigate(['./dashboard/pre-registration/CMS'])
              } else if (this.typePreRegistration === 'others') {
                await this.router.navigate(['./dashboard/pre-registration'])
              }
              this.snackBar.open('Pré-Cadastro criado com sucesso.')
            }
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
      }
    }
    if (this.typePreRegistration === 'architects') {
      if (this.approveDoc) {
        if (this.approveDoc.indexOf('.pdf') <= 0 && this.approveDoc.indexOf('.png') <= 0 && this.approveDoc.indexOf('.jpg') <= 0 && this.approveDoc.indexOf('.jpeg') <= 0) {
          this.snackBar.open('Documento Arquiteto precisa ser PDF, JPG ou PNG .')
          return false
        }
      }
      const data = {
        cpf: this.formGroup.value.cpf || this.cpf,
        firstName: this.formGroup.value.firstName,
        lastName: this.formGroup.value.lastName,
        gender: this.formGroup.value.gender,
        emailAddress: this.formGroup.value.emailAddress,
        phoneNumber: this.formGroup.value.phoneNumber,
        membershipEnabled: this.formGroup.value.membershipEnabled,
        imgDocumentFront: this.readUrlDocumentFront,
        imgDocumentBehind: this.readUrlDocumentBehind,
        birthDate: this.formGroup.value.birthDate ? moment(this.formGroup.value.birthDate).format('YYYY-MM-DD') : null,
        isMembership: this.formGroup.value.isMembership,
        planMembership: this.formGroup.value.planMembership,
        firstPayment: this.formGroup.value.firstPayment,
        documentFront: this.readUrlDocumentFront ? this.documentFront : undefined,
        documentBehind: this.readUrlDocumentBehind ? this.documentBehind : undefined,
        balance: this.formGroup.value.jcoins,
        storeId: this.formGroup.value.store ? this.formGroup.value.store.id : undefined,
        document: this.documentUrl ? this.documentUrl : undefined,
        documentName: this.approveDoc,
        approved: this.approvedDocument,
        disapproved: this.disapprovedDocument ? this.formGroup.value.disapproved : undefined,
        companyCnpj: this.selectedCompanies.length ?
          this.selectedCompanies[0].cnpj :
          this.companyCnpj,
        companyName: this.formGroup.value.companyName,
        // asbea: this.formGroup.value.asbea,
        // cau: this.formGroup.value.cau,
        // crea: this.formGroup.value.crea,
        documentType: this.formGroup.value.documentType || undefined,
        documentCode: this.formGroup.value.documentCode,
        profession: this.formGroup.value.profession,
        isStep1Done: this.preRegistrationID ? this.formGroup.value.step1 === 'true' : undefined,
        origin: this.formGroup.value.origin ? this.formGroup.value.origin : undefined,
        nickname: this.formGroup.value.nickname,
        otherProfession: this.formGroup.value.otherProfession,
        operatingTime: this.formGroup.value.operatingTime,
        // academicEducation: this.formGroup.value.academicEducation,
        maritalStatus: this.formGroup.value.maritalStatus,
        facebook: this.formGroup.value.facebook,
        instagram: this.formGroup.value.instagram,
        regType: this.formGroup.value.regType,
        licensePlate: this.formGroup.value.licensePlate,
        storeReferralName: this.formGroup.value.storeReferralName,
      }
      if (this.preRegistrationID) {
        await this.preRegistrationService.updateArchitect(this.preRegistrationID, data).then(
          async response => {
            if (response.isUpdated) {
              await this.router.navigate(['./dashboard/pre-registration-architects/architects'])
              this.snackBar.open('Pré-Cadastro atualizada com sucesso.')
            }
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
      } else {
        await this.preRegistrationService.createArchitect(data).then(
          async response => {
            if (response.isCreated) {
              await this.router.navigate(['./dashboard/pre-registration-architects/architects'])
              this.snackBar.open('Pré-Cadastro criado com sucesso.')
            }
          },
          error => {
            this.snackBar.open(error.error.message)
          },
        )
      }
    }
  }
}
