import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { AddressClient } from '../models/addressClient'
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public loading

  constructor(private readonly http: HttpClient) { }

  signin(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/auth/cms/signin`, data).pipe(
      tap(_ => this.log('auth/signin')),
      catchError(this.handleError),
    )
  }

  passwordRecover(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/auth/cms/password/recover`, data).pipe(
      tap(_ => this.log('auth/cms/password/recover')),
      catchError(this.handleError),
    )
  }

  resendCredentials(userID): Observable<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/profiles/customers/${userID}/reset-password`, {})
      .pipe(
        tap(_ => this.log('auth/cms/reset-password')),
        catchError(this.handleError),
      )
  }

  passwordUpdate(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/auth/cms/password/update`, data).pipe(
      tap(_ => this.log('auth/cms/password/update')),
      catchError(this.handleError),
    )
  }

  emailConfirmation(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/auth/cms/signin`, data).pipe(
      tap(_ => this.log('auth/signin')),
      catchError(this.handleError),
    )
  }

  signup(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/users`, data).pipe(
      tap(_ => this.log('cms/users')),
      catchError(this.handleError),
    )
  }

  update(data, userID): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/cms/users/${userID}`, data).pipe(
      tap(_ => this.log('auth/update')),
      catchError(this.handleError),
    )
  }

  signupClient(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/profiles/signup`, data).pipe(
      tap(_ => this.log('cms/profiles/signup')),
      catchError(this.handleError),
    )
  }

  userInfo(userID): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/profiles/${userID}`).pipe(
      tap(_ => this.log('cms/profiles')),
      catchError(this.handleError),
    )
  }

  getStoresByUser(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/profiles/stores`).pipe(
      tap(_ => this.log('cms/profiles/stores')),
      catchError(this.handleError),
    )
  }

  customerUserInfo(customerID): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/profiles/customers/${customerID}`).pipe(
      tap(_ => this.log('cms/profiles/customers')),
      catchError(this.handleError),
    )
  }

  customerUpdate(customerID, data): Observable<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/profiles/customers/${customerID}`, data)
      .pipe(
        tap(_ => this.log('cms/profiles/customers/update')),
        catchError(this.handleError),
      )
  }

  private handleError(error: any) {
    return throwError(error)
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) { }

  async clientAddressList(customerID: string): Promise<AddressClient[]> {
    return this.http
      .get<AddressClient[]>(`${environment.apiUrl}/cms/addresses/${customerID}`)
      .toPromise()
  }

  async createAddress(data): Promise<any> {
    return this.http.post<any>(`${environment.apiUrl}/cms/addresses/`, data).toPromise()
  }

  async delete(addressId: string, customerId: string): Promise<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/addresses/${addressId}/customer/${customerId}`)
      .toPromise()
  }

  async addressFindOne(addressesID: string, customerID: string): Promise<AddressClient> {
    return this.http
      .get<AddressClient>(
        `${environment.apiUrl}/cms/addresses/${addressesID}/customer/${customerID}`,
      )
      .toPromise()
  }

  async updateAddress(id: string, data): Promise<any> {
    return this.http.put<any>(`${environment.apiUrl}/cms/addresses/${id}`, data).toPromise()
  }

  async listMovementJcoins(cpf, filter?): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/customers/movement-jcoins/${cpf}${filter || ''}`)
      .toPromise()
  }

  async deleteCustomer(customerId: string): Promise<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/profiles/customer/${customerId}/remove`)
      .toPromise()
  }

  async lisCards(customerId): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/cards/costumer/${customerId}`).toPromise()
  }

  async getS3Url(type): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/profiles/s3-slot/${type}`).toPromise()
  }

  async uploadToS3(pathAwsUpload: string, data): Promise<void> {
    await this.http.put<any>(`${pathAwsUpload}`, data).toPromise()
  }

  async clientKindList(filter?): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/profiles/customers/kind${filter || ''}`)
      .toPromise()
  }

  async clientKindDelete(id): Promise<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/profiles/customers/kind/${id}`)
      .toPromise()
  }

  async ClientKindFindOne(id): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/profiles/customers/kind/${id}`).toPromise()
  }

  async ClientKindUpdate(id, data): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/profiles/customers/kind/${id}`, data)
      .toPromise()
  }

  async ClientKindCreate(data): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/profiles/customers/kind`, data)
      .toPromise()
  }

  async importCustomers(data): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/profiles/customers/import-excel`, data)
      .toPromise()
  }

  async clientsBeneficiaries(filter?): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/customers/beneficiaries${filter || ''}`)
      .toPromise()
  }

  async clientsBeneficiaryDelete(id): Promise<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/customer/beneficiary/${id}`)
      .toPromise()
  }

  async createBeneficiary(data): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/customer/beneficiary`, data)
      .toPromise()
  }

  async updateBeneficiary(id, data): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/customer/beneficiary/${id}`, data)
      .toPromise()
  }

  async findOneBeneficiary(id): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/customer/beneficiary/${id}`).toPromise()
  }

  async clientsIncorporations(filter?): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/incorporations${filter || ''}`)
      .toPromise()
  }

  async closingsIntegrations(): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/incorporations/closings`)
      .toPromise()
  }

  async closingIntegrationCreate(data): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/incorporations/closings`, data)
      .toPromise()
  }

  async closingsIntegrationsIncorporations(id): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/incorporations/closing-incorporations/${id}`)
      .toPromise()
  }

  async deleteClosingIncorporations(id): Promise<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/incorporations/closing/${id}`)
      .toPromise()
  }

  async documentsNotIntegration(): Promise<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/cms/cpf-incorporations`)
      .toPromise()
  }

  async createDocumentsNotIntegration(data): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/cpf-incorporation`, data)
      .toPromise()
  }

  async updateDocumentsNotIntegration(id, data): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/cpf-incorporation/${id}`, data)
      .toPromise()
  }

  async findOneDocumentsNotIntegration(id): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/cpf-incorporation/${id}`).toPromise()
  }

  async deleteDocumentsNotIntegration(id): Promise<any> {
    return this.http
      .delete<any>(`${environment.apiUrl}/cms/cpf-incorporation/${id}`)
      .toPromise()
  }

  async importCustomersPreRegister(data): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/company-airport-customer/import-excel`, data)
      .toPromise()
  }

  async deleteProfile(userId, storeId): Promise<any> {
    return this.http.delete<any>(`${environment.apiUrl}/cms/profile?userId=${userId}&storeId=${storeId}`).toPromise()
  }

  async listProfilesDeleted(id): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/profiles-deleted/store/${id}`).toPromise()
  }

  async unrelateUserWithStoreBusinessUnit(userId, storeBusinessUnitId): Promise<any> {
    return this.http.delete<any>(`${environment.apiUrl}/cms/profile/store-business-units?userId=${userId}&storeBusinessUnitId=${storeBusinessUnitId}`).toPromise()
  }

  async findOneCustomerArchitect(id): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/customer-architects/${id}`).toPromise()
  }

  async customerUpdateArchitect(id, data): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/customer-architects/${id}`, data)
      .toPromise()
  }

  async listCustomerTerms(cpf): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/customer/terms/${cpf}`).toPromise()
  }

  async createCustomerTerm(data): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/cms/customer/terms`, data)
      .toPromise()
  }

  async findOneCustomerTerm(id): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/customer/term/${id}`).toPromise()
  }

  async updateCustomerTerm(id, data): Promise<any> {
    return this.http
      .put<any>(`${environment.apiUrl}/cms/customer/term/${id}`, data)
      .toPromise()
  }

  async deleteCustomerTerm(id): Promise<any> {
    return this.http.delete<any>(`${environment.apiUrl}/cms/customer/term/${id}`).toPromise()
  }
}
