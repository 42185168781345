<app-logged class="store-list">
  <header class="store-list__header" *ngIf="schedule" >
    <h2>
      AGENDAMENTO DE TRANSFERENCIA {{schedule.reason == 'liquidation' ? 'DE LIQUIDAÇÃO ': ''}} - Parceiro: {{ schedule.transfer[0].storeName }}
    </h2>
    <div class="store-list__header__actions">
      <button
        class="btn-primary-light"
        mat-button
        (click)="goBack()"
      >
        VOLTAR
      </button>
    </div>
  </header>

  <div *ngIf="schedule">
    <div>
      <strong>Status:</strong>
      <span *ngIf="schedule.status == enumTransferApprovalStatus.PENDING_APPROVAL" class="pending">
        Aprovação Pendente
      </span>
      <span *ngIf="schedule.status == enumTransferApprovalStatus.APPROVAL_GRANTED" class="approved">
        Aprovação Concedida
      </span>
      <span *ngIf="schedule.status == enumTransferApprovalStatus.APPROVAL_REJECTED" class="reject">
        Aprovação Rejeitada
      </span>
      <span *ngIf="schedule.status == enumTransferApprovalStatus.APPROVAL_EXPIRED" class="expired">
        Aprovação Expirada
      </span>
      <span *ngIf="schedule.status == enumTransferApprovalStatus.ERROR" class="error">
        Erro
      </span>
      <span *ngIf="schedule.status == enumTransferApprovalStatus.EXECUTED" class="executed">
        Executado
      </span>
    </div>

    <div>
      <strong> CNPJ:</strong>
      {{ schedule.transfer[0].storeCnpj | mask: '00.000.000/0000-00' }}
    </div>

    <div>
      <strong> Agendado para:</strong>
      {{ schedule.dueDate | date: 'dd/MM/yyyy' }}
    </div>
    <div>
      <strong>Criado em:</strong>
      {{ schedule.createdAt | date: 'dd/MM/yyyy' }}
    </div>

    <div>
      <strong>Pago em:</strong>
      {{ schedule.paidAt | date: 'dd/MM/yyyy' }}
    </div>

    <div>
      <strong>Transferência autorizada/desautorizada em:</strong>
      {{ getLastStatusUpdateDate() | date: 'dd/MM/yyyy' }}
    </div>

    <div>
      <strong>Status da aprovação:</strong>
      {{ getStatus() }}
    </div>

    <div>
      <strong>Tipo:</strong>
      {{
        schedule.reason == 'liquidation'
          ? 'Liquidação de parceiros'
          : 'Transferencias de taxa administrativa'
      }}
    </div>
  </div>

  <div class="row" *ngIf="transfers">
    <div class="col-sm-12">
      <div class="table-responsive">
        <table mat-table [dataSource]="transfers">
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>Criado em</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdAt | date: 'dd/MM/yyyy HH:mm' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="totalValue">
            <th mat-header-cell *matHeaderCellDef>Valor</th>
            <td mat-cell *matCellDef="let element">
              {{ element.totalValue | currency: 'R$' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.status == enumTransferApprovalStatus.PENDING_APPROVAL" class="pending">
                Aprovação Pendente
              </span>
              <span *ngIf="element.status == enumTransferApprovalStatus.APPROVAL_GRANTED" class="approved">
                Aprovação Concedida
              </span>
              <span *ngIf="element.status == enumTransferApprovalStatus.APPROVAL_REJECTED" class="reject">
                Aprovação Rejeitada
              </span>
              <span *ngIf="element.status == enumTransferApprovalStatus.APPROVAL_EXPIRED" class="expired">
                Aprovação Expirada
              </span>
              <span *ngIf="element.status == enumTransferApprovalStatus.ERROR" class="error">
                Erro
              </span>
              <span *ngIf="element.status == enumTransferApprovalStatus.EXECUTED" class="executed">
                Executado
              </span>
              <span *ngIf="element.status === null" class="executed">
                Processando
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="executedAt">
            <th mat-header-cell *matHeaderCellDef>Executado em</th>
            <td mat-cell *matCellDef="let element">
              {{ element.executedAt | date: 'dd/MM/yyyy' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Ações</th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-icon-button
                matTooltip="Detalhes"
                matTooltipPosition="left"
                (click)="openTransferDetails(element)"
              >
                <mat-icon>remove_red_eye</mat-icon>
              </button>

              <button
                mat-icon-button
                matTooltip="Verificar logs"
                matTooltipPosition="left"
                (click)="openTransferLogs(element)"
              >
              <mat-icon>list_alt</mat-icon>
              </button>
             
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
          <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
        </table>

       
      </div>
      
    </div>
    
  </div>
  <div *ngIf="enableRetryButton">
    <button          
    mat-button
    matTooltip="Reenviar Solicitação de TED - Enviar novamente ao banco arbi para autorização"
    matTooltipPosition="right"
    class="btn-primary-light"
    [disabled]="retryButtonsClicked[1] ? 'disabled' : null"
    (click)="retrySendToApproval()"
  >
    <mat-icon>history</mat-icon> Reenviar Solicitação de TED
  </button>
  </div>
</app-logged>
