import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ModalConfirmationPointsComponent } from 'src/app/components/modal-confirmation-points/modal-confirmation-points.component';
import { map, startWith } from 'rxjs/operators';
var ManualJcoinCreditFormComponent = /** @class */ (function () {
    function ManualJcoinCreditFormComponent(service, errorsService, formBuilder, storeService, authService, router, route, snackBar, dialog, location, companiesService) {
        this.service = service;
        this.errorsService = errorsService;
        this.formBuilder = formBuilder;
        this.storeService = storeService;
        this.authService = authService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.location = location;
        this.companiesService = companiesService;
        this.states = [];
        this.plans = [];
        this.userID = '';
        this.stores = [];
        this.user = [];
        this.storeName = '';
        this.store = [];
        this.params = { formGroupValue: [] };
        this.initForm().catch(function (e) { return console.log(e); });
    }
    ManualJcoinCreditFormComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.route.queryParams.subscribe(function (params) {
                            _this.params.formGroupValue = params.formGroupValue;
                        });
                        this.userID = this.route.snapshot.paramMap.get('customerID');
                        this.architects = this.route.snapshot.paramMap.get('architects');
                        this.loadInfosUser();
                        if (!this.architects) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadStoresArchitects().catch(function (e) { return console.log(e); })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.loadStores()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ManualJcoinCreditFormComponent.prototype.initForm = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.formGroup = this.formBuilder.group({
                    name: [{ value: null, disabled: true }, [Validators.required]],
                    cpf: [{ value: null, disabled: true }, [Validators.required]],
                    jcoins: [
                        null,
                        [Validators.required, Validators.min(1), Validators.pattern('^(0|[1-9][0-9]*)$')],
                    ],
                    pointsConversionFactorOut: [{ value: null, disabled: true }, []],
                    store: [null, [Validators.required]],
                    commit: ['', [Validators.max(60)]],
                });
                this.storeOptions = this.formGroup.controls.store.valueChanges.pipe(startWith(''), map(function (value) {
                    return _this.stores.filter(function (store) {
                        var newValue = typeof value === 'object' ? value.nomeFantasia : value;
                        var nomeFantasia = store.nomeFantasia ? store.nomeFantasia.toLowerCase() : '';
                        var razaoSocial = store.razaoSocial ? store.razaoSocial.toLowerCase() : '';
                        var cnpj = store.cnpj ? store.cnpj.toLowerCase() : '';
                        return (nomeFantasia.includes(newValue.toLowerCase()) ||
                            razaoSocial.includes(newValue.toLowerCase()) ||
                            cnpj.includes(newValue.toLowerCase()));
                    });
                }));
                return [2 /*return*/];
            });
        });
    };
    ManualJcoinCreditFormComponent.prototype.loadInfosUser = function () {
        var _this = this;
        this.authService.customerUserInfo(this.userID).subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.user = response;
                this.formGroup.patchValue({
                    name: response.firstName + ' ' + response.lastName,
                    cpf: response.cpf,
                });
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    ManualJcoinCreditFormComponent.prototype.loadStoresArchitects = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var stores;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.companiesService
                            .listStoresBusinessUnits('?page=1&limit=10000')
                            .catch(function (err) {
                            _this.snackBar.open(err.message);
                            return { stores: [], TotalRegisters: 0 };
                        })];
                    case 1:
                        stores = (_a.sent()).stores;
                        this.stores = stores;
                        return [2 /*return*/];
                }
            });
        });
    };
    ManualJcoinCreditFormComponent.prototype.loadStores = function () {
        var _this = this;
        this.storeService.listStores().subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.stores = response;
                return [2 /*return*/];
            });
        }); }, function (error) {
            console.log(error);
            _this.snackBar.open(error.error.message);
        });
    };
    ManualJcoinCreditFormComponent.prototype.calcValuePoints = function (event) {
        this.formGroup.patchValue({
            store: this.formGroup.value.store,
            jcoins: event.target.value,
            pointsConversionFactorOut: this.formGroup.value.jcoins * (this.store[0].pointsConversionFactorOut / 100),
        });
        this.pointsConversionFactorOut =
            this.formGroup.value.jcoins * (this.store[0].pointsConversionFactorOut / 100);
    };
    ManualJcoinCreditFormComponent.prototype.displayStore = function (store) {
        return store ? "" + store.nomeFantasia : '';
    };
    ManualJcoinCreditFormComponent.prototype.calcValue = function (event) {
        var _this = this;
        this.stores.forEach(function (store) {
            if (store.id == event.option.value.id) {
                _this.store.push(store);
                _this.storeName = store.nomeFantasia;
                _this.pointsConversionFactorOut =
                    _this.formGroup.value.jcoins * (_this.store[0].pointsConversionFactorOut / 100);
                _this.formGroup.patchValue({
                    store: _this.formGroup.value.store,
                    pointsConversionFactorOut: _this.formGroup.value.jcoins * (_this.store[0].pointsConversionFactorOut / 100),
                });
            }
        });
    };
    ManualJcoinCreditFormComponent.prototype.goBack = function () {
        this.router.navigate(['./dashboard/manual-jcoin-credit']);
    };
    ManualJcoinCreditFormComponent.prototype.submit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, dialogRef;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this.formGroup.valid) {
                    this.snackBar.open('Preencha corretamente os campos e tente novamente.');
                    return [2 /*return*/, false];
                }
                if (!this.formGroup.value.store || !_.isObject(this.formGroup.value.store)) {
                    this.snackBar.open('Preencha corretamente o campo Parceiro.');
                    return [2 /*return*/, false];
                }
                if (this.formGroup.value.commit.length > 60) {
                    this.snackBar.open('O campo Comentário tem limite máximo de 60 caracteres');
                    return [2 /*return*/, false];
                }
                data = {
                    customerId: this.userID,
                    storeId: this.formGroup.value.store.id,
                    jcoins: parseInt(this.formGroup.value.jcoins),
                    commit: this.formGroup.value.commit,
                };
                dialogRef = this.dialog.open(ModalConfirmationPointsComponent, {
                    width: '500px',
                    data: {
                        store: this.storeName,
                        points: this.formGroup.value.jcoins,
                        value: this.pointsConversionFactorOut,
                    },
                });
                dialogRef.afterClosed().subscribe(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var req;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!(result && result != 'cancel')) return [3 /*break*/, 2];
                                return [4 /*yield*/, this.service.request(data)];
                            case 1:
                                req = _a.sent();
                                this.snackBar.open('Solicitação de pontos efetuada com sucesso.');
                                this.router.navigate(['./dashboard/manual-jcoin-credit']);
                                _a.label = 2;
                            case 2: return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    return ManualJcoinCreditFormComponent;
}());
export { ManualJcoinCreditFormComponent };
