import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ManualJcoinCreditService = /** @class */ (function () {
    function ManualJcoinCreditService(http) {
        this.http = http;
    }
    ManualJcoinCreditService.prototype.list = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(environment.apiUrl + "/cms/manual-jcoins-credit?" + params).toPromise()];
            });
        });
    };
    ManualJcoinCreditService.prototype.request = function (body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_a) {
                url = environment.apiUrl + "/cms/manual-jcoins-credit";
                return [2 /*return*/, this.http.post(url, body).toPromise()];
            });
        });
    };
    ManualJcoinCreditService.prototype.review = function (id, body) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            return tslib_1.__generator(this, function (_a) {
                url = environment.apiUrl + "/cms/manual-jcoins-credit/" + id + "/review";
                return [2 /*return*/, this.http.put(url, body).toPromise()];
            });
        });
    };
    ManualJcoinCreditService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManualJcoinCreditService_Factory() { return new ManualJcoinCreditService(i0.ɵɵinject(i1.HttpClient)); }, token: ManualJcoinCreditService, providedIn: "root" });
    return ManualJcoinCreditService;
}());
export { ManualJcoinCreditService };
