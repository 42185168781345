import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ManualJcoinCreditService {
  public loading;

  constructor(private readonly http: HttpClient) { }

  async list(params: string): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/cms/manual-jcoins-credit?${params}`).toPromise()
  }

  async request(body: any): Promise<any> {
    const url = `${environment.apiUrl}/cms/manual-jcoins-credit`;
    return this.http.post<any>(url, body).toPromise();
  }

  async review(id: string, body: any): Promise<any> {
    const url = `${environment.apiUrl}/cms/manual-jcoins-credit/${id}/review`;
    return this.http.put<any>(url, body).toPromise();
  }
}
