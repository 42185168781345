<app-logged class="store-list">
  <header class="store-list__header">
    <h2>FECHAMENTO FINANCEIRO</h2>
    <div class="store-list__header__actions">
      <button class="btn-primary-light" (click)="openModal()">ADICIONAR</button>
    </div>
  </header>
  <div class="table-responsive">
    <table mat-table [dataSource]="closings">
      <ng-container matColumnDef="closingNumber">
        <th mat-header-cell *matHeaderCellDef>Números de Fechametos</th>
        <td mat-cell *matCellDef="let element">
          {{ element.closingNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="startDateEndendDate">
        <th mat-header-cell *matHeaderCellDef>Paríodo</th>
        <td mat-cell *matCellDef="let element">
          {{ element.startDate | date: 'dd/MM/yyyy':'GMT -0300' }} até
          {{ element.endDate | date: 'dd/MM/yyyy':'GMT -0300' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Ações</th>
        <td mat-cell *matCellDef="let element">
          <!-- <button
            mat-icon-button
            matTooltip="Detalhes"
            matTooltipPosition="left"
            routerLink="/dashboard/closings-incorporations-integrations/{{ element.id }}"
          >
            <mat-icon>home_work</mat-icon>
          </button> -->
          <button
            mat-icon-button
            matTooltip="Exportar"
            matTooltipPosition="left"
            (click)="export(element.id)"
          >
            <mat-icon>system_update_alt</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Exportar"
            matTooltipPosition="left"
            (click)="delete(element.id)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="headersTable"></tr>
      <tr mat-row *matRowDef="let row; columns: headersTable"></tr>
    </table>
  </div>
</app-logged>
