import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser'
import { NgModule, LOCALE_ID, NO_ERRORS_SCHEMA } from '@angular/core'
import { MatPaginatorModule } from '@angular/material/paginator'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CurrencyMaskModule } from 'ng2-currency-mask'
import { MatChipsModule } from '@angular/material/chips'
import { MatIconModule } from '@angular/material/icon'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatCheckboxModule } from '@angular/material/checkbox';

import {
  MatInputModule,
  MatFormFieldModule,
  MatTableModule,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSelectModule,
  MatSortModule,
  MatAutocompleteModule,
  MatRadioModule,
} from '@angular/material'

import { LyTheme2, StyleRenderer, LY_THEME, LY_THEME_NAME, LyHammerGestureConfig } from '@alyle/ui'
import { LyImageCropperModule } from '@alyle/ui/image-cropper'
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima'
import { LySliderModule } from '@alyle/ui/slider'
import { LyButtonModule } from '@alyle/ui/button'
import { LyIconModule } from '@alyle/ui/icon'
import { LyDialogModule } from '@alyle/ui/dialog'

import { NgxImageCompressService } from 'ngx-image-compress'

import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { HighchartsChartModule } from 'highcharts-angular'
import { UnloggedModule } from './components/unlogged/unlogged.module'
import { LoggedModule } from './components/logged/logged.module'

import { RequestInterceptor } from './interceptors/request.interceptor'
import { AuthGuardService } from './guards/auth-guard.service'
import { UtilService } from './services/util.service'
import { AuthModule } from './modules/auth/auth.module'
import { DashboardModule } from './modules/dashboard/dashboard.module'
import { LoaderComponent } from './components/loader/loader.component'
import { NotFoundComponent } from './components/not-found/not-found.component'
import localePt from '@angular/common/locales/pt'
import { registerLocaleData, DecimalPipe, DatePipe, CurrencyPipe } from '@angular/common'
import br from '@angular/common/locales/br'
import { ModalComponent } from './components/modal/modal.component'
import { NgxMaskModule } from 'ngx-mask'
import { ModalCancelTransactionComponent } from './components/modal-cancel-transaction/modal-cancel-transaction.component'
import { ModalChooseTransactionTypeComponent } from './components/modal-choose-transaction-type/modal-choose-transaction-type.component'
import { ModalResendCredentialsComponent } from './components/modal-resend-credentials/modal-resend-credentials.component'
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component'
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component'
import { ModalConfirmationPointsComponent } from './components/modal-confirmation-points/modal-confirmation-points.component'

import { ModalRejectComponent } from './components/modal-reject/modal-reject.component'
import { ModalRoadpassUnableToCancel } from './components/modal-roadpass-unable-to-cancel/modal-roadpass-unable-to-cancel.component'
import { ModalChooseStoreComponent } from './components/modal-choose-store/modal-choose-store.component'
import { ModalDeleteClosingComponent } from './components/modal-delete-closing/modal-delete-closing.component'
import { ModalUserComponent } from './components/modal-user/modal-user.component'
import { ModalClosingsComponent } from './components/modal-closings/modal-closings.component'
import { ModalHelpComponent } from './components/modal-help/modal-help.component'
import { ModalCustomerInfoComponent } from './components/modal-customer-info/modal-customer-info.component'
import { ModalCustomerCpfComponent } from './components/modal-customer-cpf/modal-customer-cpf.component'
import { ModalFormMonthlyPaymentComponent } from './components/modal-form-monthlyPayment/modal-form-monthlyPayment.component'
import { ModalFormNotificationQuizComponent } from './components/modal-form-notification-quiz/modal-form-notification-quiz.component'
import { ModalFormAccumulationComponent } from './components/modal-form-accumulation/modal-form-accumulation.component'
import { ModalFormRescueComponent } from './components/modal-form-rescue/modal-form-rescue.component'
import { ModalFormBalanceJCoinsComponent } from './components/modal-form-balanceJCoins/modal-form-balanceJCoins.component'
import { ModalFormReceivablesComponent } from './components/modal-form-receivables/modal-form-receivables.component'
import { ModalFormGeneralComponent } from './components/modal-form-general/modal-form-general.component'
import { ModalFormStatementByCustomerComponent } from './components/modal-form-statementByCustomer/modal-form-statementByCustomer.component'
import { CropperDialogComponent } from './components/cropper-dialog/cropper-dialog.component'
import { ModalCreateTransactionAnticipatedComponent } from './components/modal-create-transaction-anticipated/modal-create-transaction-anticipated.component'
import { ModalFormTransactionsComponent } from './components/modal-form-transactions/modal-form-transactions.component'
import { ModalFormTransactionsAdminComponent } from './components/modal-form-transactions-admin/modal-form-transactions-admin.component'
import { ModalCustomersUniqueComponent } from './components/modal-form-customersUnique/modal-form-customersUnique.component'
import { ModalIntegrationsClosingsComponent } from './components/modal-integrations-closings/modal-closings.component'
import { CampaignFilterPipe } from './pipes/campaign-filter.pipe'
import { ModalChooseCampaignTypeComponent } from './components/modal-choose-campaign-type/modal-choose-campaign-type.component'
import { ModalVerifyStoreCampaignTypeComponent } from './components/modal-verify-store-campaign-type/modal-verify-store-campaign-type.component'
import { ModalVerifyInitialCampaignDateComponent } from './components/modal-verify-initial-campaign-date/modal-verify-initial-campaign-date.component'
import { ModalPartnerEditionMacroBindingInformation } from './components/modal-partner-edition-macro-binding-information/modal-partner-edition-macro-binding-information.component'
import { ModalFormChargeBackComponent } from './components/modal-form-charge-back/modal-form-charge-back.component'
import { ModalInstallmentsComponent } from './components/modal-installments/modal-installments.component'
import { ModalFormCustomerMonthBalanceComponent } from './components/modal-form-customerMonthBalance/modal-form-customerMonthBalance.component'
import { ModalFormTransactionsc2cComponent } from './components/modal-form-transactions-c2c/modal-form-transactions-c2c.component'
import { ModalFormAccumulationManualComponent } from './components/modal-form-accumulation-manual/modal-form-accumulation-manual.component'
import { ModalFormAddCustomerAirportCompanyComponent } from './components/modal-form-addCustomerAirportCompany/modal-form-addCustomerAirportCompany.component'
import { ModalFormAccumulationCardMachineComponent } from './components/modal-form-accumulation-cardMachine/modal-form-accumulation-cardMachine.component'
import { ModalGenericComponent } from './components/modal-generic/modal-generic.component'
import { ModalRejectGenericComponent } from './components/modal-reject-generic/modal-reject-generic.component'
import { ModalLateArchitectInvoices } from './components/modal-late-architect-invoices/modal-late-architect-invoices.component'
import { ModalLiquidationTransferDetails } from './modules/dashboard/liquidation-transfer/modal-liquidation-transfer-details/modal-liquidation-transfer-details.component'
import { ModalTransferLogs } from './modules/dashboard/liquidation-transfer/modal-transfer-logs/modal-transfer-logs.component'
import { ModalTransferManualComponent } from './components/modal-transfer-manual/modal-transfer-manual.component'
import { ModalConfirmationInternationalTransactionComponent } from './components/modal-confirmation-international-transaction/modal-confirmation-international-transaction.component'
import { ModalStoreBankAccountChangedComponent } from './components/modal-store-bank-account-changed/modal-store-bank-account-changed.component'
import { ModalFormLiquidationTransferDetailedReport } from './components/modal-form-liquidation-transfer-detailed-report/modal-form-liquidation-transfer-detailed-report.component'
import { ModalReviewFormComponent } from './modules/dashboard/manual-jcoin-credit/modal-review-form/modal-review-form.component'
import { ModalManualTedRequestComponent } from './components/modal-manual-ted-request/modal-manual-ted-request.component'
import { ModalFormIntegrationsComponent } from './components/modal-form-integrations/modal-form-integrations.component'

// registerLocaleData(br, 'pt-BR');
registerLocaleData(localePt)

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    NotFoundComponent,
    ModalComponent,
    ModalReviewFormComponent,
    ModalCancelTransactionComponent,
    ModalChooseTransactionTypeComponent,
    ModalChooseCampaignTypeComponent,
    ModalVerifyStoreCampaignTypeComponent,
    ModalVerifyInitialCampaignDateComponent,
    ModalResendCredentialsComponent,
    ModalConfirmationComponent,
    ModalRejectComponent,
    ModalChooseStoreComponent,
    ModalDeleteClosingComponent,
    ModalConfirmationPointsComponent,
    ModalConfirmComponent,
    ModalUserComponent,
    ModalClosingsComponent,
    ModalHelpComponent,
    ModalCustomerInfoComponent,
    ModalCustomerCpfComponent,
    ModalFormMonthlyPaymentComponent,
    ModalFormNotificationQuizComponent,
    ModalFormAccumulationComponent,
    ModalFormRescueComponent,
    ModalFormBalanceJCoinsComponent,
    ModalFormReceivablesComponent,
    ModalFormGeneralComponent,
    ModalFormStatementByCustomerComponent,
    CropperDialogComponent,
    ModalCreateTransactionAnticipatedComponent,
    ModalFormTransactionsComponent,
    ModalFormTransactionsAdminComponent,
    ModalCustomersUniqueComponent,
    ModalIntegrationsClosingsComponent,
    ModalPartnerEditionMacroBindingInformation,
    ModalFormChargeBackComponent,
    ModalInstallmentsComponent,
    ModalRoadpassUnableToCancel,
    ModalFormCustomerMonthBalanceComponent,
    ModalFormTransactionsc2cComponent,
    ModalFormAccumulationManualComponent,
    ModalFormAddCustomerAirportCompanyComponent,
    ModalFormAccumulationCardMachineComponent,
    ModalGenericComponent,
    ModalRejectGenericComponent,
    ModalLateArchitectInvoices,
    ModalLiquidationTransferDetails,
    ModalTransferLogs,
    ModalTransferManualComponent,
    ModalConfirmationInternationalTransactionComponent,
    ModalStoreBankAccountChangedComponent,
    ModalFormLiquidationTransferDetailedReport,
    ModalManualTedRequestComponent,
    ModalFormIntegrationsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LyImageCropperModule,
    LySliderModule,
    LyButtonModule,
    LyIconModule,
    LyDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    CurrencyMaskModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    UnloggedModule,
    LoggedModule,
    AppRoutingModule,
    AuthModule,
    DashboardModule,
    CurrencyMaskModule,
    MatSelectModule,
    DragDropModule,
    HighchartsChartModule,
    MatRadioModule,
    NgxMaskModule.forRoot(),
    MatCheckboxModule,
  ],
  providers: [
    DecimalPipe,
    DatePipe,
    CurrencyPipe,
    CampaignFilterPipe,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 5000 },
    },
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    AuthGuardService,
    UtilService,
    [LyTheme2],
    [StyleRenderer],
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
    { provide: LY_THEME, useClass: MinimaDark, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig },
    [NgxImageCompressService],
  ],
  schemas: [NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalComponent,
    ModalChooseCampaignTypeComponent,
    ModalReviewFormComponent,
    ModalCancelTransactionComponent,
    ModalChooseTransactionTypeComponent,
    ModalVerifyStoreCampaignTypeComponent,
    ModalVerifyInitialCampaignDateComponent,
    ModalResendCredentialsComponent,
    ModalConfirmationComponent,
    ModalRejectComponent,
    ModalChooseStoreComponent,
    ModalDeleteClosingComponent,
    ModalConfirmationPointsComponent,
    ModalConfirmComponent,
    ModalUserComponent,
    ModalClosingsComponent,
    ModalHelpComponent,
    ModalCustomerInfoComponent,
    ModalCustomerCpfComponent,
    ModalFormMonthlyPaymentComponent,
    ModalFormNotificationQuizComponent,
    ModalFormAccumulationComponent,
    ModalFormRescueComponent,
    ModalFormBalanceJCoinsComponent,
    ModalFormReceivablesComponent,
    ModalFormGeneralComponent,
    ModalFormStatementByCustomerComponent,
    CropperDialogComponent,
    ModalCreateTransactionAnticipatedComponent,
    ModalFormTransactionsComponent,
    ModalFormTransactionsAdminComponent,
    ModalCustomersUniqueComponent,
    ModalIntegrationsClosingsComponent,
    ModalPartnerEditionMacroBindingInformation,
    ModalFormChargeBackComponent,
    ModalInstallmentsComponent,
    ModalRoadpassUnableToCancel,
    ModalFormCustomerMonthBalanceComponent,
    ModalFormTransactionsc2cComponent,
    ModalFormAccumulationManualComponent,
    ModalFormAddCustomerAirportCompanyComponent,
    ModalFormAccumulationCardMachineComponent,
    ModalGenericComponent,
    ModalRejectGenericComponent,
    ModalLateArchitectInvoices,
    ModalLiquidationTransferDetails,
    ModalTransferLogs,
    ModalTransferManualComponent,
    ModalConfirmationInternationalTransactionComponent,
    ModalStoreBankAccountChangedComponent,
    ModalFormLiquidationTransferDetailedReport,
    ModalManualTedRequestComponent,
    ModalFormIntegrationsComponent,
  ],
})
export class AppModule { }
