import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

interface DialogData {
  startDate: string;
  endDate: string;
  status: string;
}

@Component({
  selector: 'app-modal-form-integrations',
  templateUrl: './modal-form-integrations.component.html',
  styleUrls: ['./modal-form-integrations.component.scss']
})
export class ModalFormIntegrationsComponent implements OnInit {

  public filterForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalFormIntegrationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    // Inicializando o formulário com os campos de data e status
    this.filterForm = this.formBuilder.group({
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      status: ['COMPUTED']
    });
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

  submit() {
    if (!this.filterForm.valid) {
      this.snackBar.open("Preencha corretamente os campos e tente novamente.");
      return false;
    }
  
    const filters = {
      startDate: this.filterForm.value.startDate,
      endDate: this.filterForm.value.endDate,
      status: this.filterForm.value.status
    };
  
    this.dialogRef.close(filters);  // Passa os filtros ao fechar o modal
  }  

  parseDate(date: string | Date): Date {
    if (!date) {
      return null;
    }
  
    // Se já for uma instância de Date, retorne a própria data
    if (date instanceof Date) {
      return date;
    }
  
    // Se for uma string, converta para o formato Date
    const parts = date.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;  // Os meses começam do 0 em JavaScript
    const year = parseInt(parts[2], 10);
  
    return new Date(year, month, day);
  }
  
}
