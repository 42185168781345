import { OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Validators } from '@angular/forms';
var ModalFormIntegrationsComponent = /** @class */ (function () {
    function ModalFormIntegrationsComponent(dialogRef, data, formBuilder, snackBar) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
    }
    ModalFormIntegrationsComponent.prototype.ngOnInit = function () {
        // Inicializando o formulário com os campos de data e status
        this.filterForm = this.formBuilder.group({
            startDate: [null, Validators.required],
            endDate: [null, Validators.required],
            status: ['COMPUTED']
        });
    };
    ModalFormIntegrationsComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    ModalFormIntegrationsComponent.prototype.submit = function () {
        if (!this.filterForm.valid) {
            this.snackBar.open("Preencha corretamente os campos e tente novamente.");
            return false;
        }
        var filters = {
            startDate: this.filterForm.value.startDate,
            endDate: this.filterForm.value.endDate,
            status: this.filterForm.value.status
        };
        this.dialogRef.close(filters); // Passa os filtros ao fechar o modal
    };
    ModalFormIntegrationsComponent.prototype.parseDate = function (date) {
        if (!date) {
            return null;
        }
        // Se já for uma instância de Date, retorne a própria data
        if (date instanceof Date) {
            return date;
        }
        // Se for uma string, converta para o formato Date
        var parts = date.split('/');
        var day = parseInt(parts[0], 10);
        var month = parseInt(parts[1], 10) - 1; // Os meses começam do 0 em JavaScript
        var year = parseInt(parts[2], 10);
        return new Date(year, month, day);
    };
    return ModalFormIntegrationsComponent;
}());
export { ModalFormIntegrationsComponent };
